import React, { Fragment, useState, useRef, useEffect, useContext } from 'react'
import './home.css'
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Banner from '../../components/Banner'
import Card from '../../components/Card'
import Plans from '../../components/Plans'
import Popups from '../../components/Popups'
import Accordion from '../../components/Accordion'
import Modal from 'react-modal'
import { questionsAnswers } from '../../data/faq'
import axios from 'axios'
import { deryptData, encryptData, getActualSubscriptionPlanBasedOnCPAddOn, getPlanList } from '../../api/Activity'
import { apiData } from '../../data/data'
import { compareData, filterOTTPlans, filterPlans, maxPlan } from '../../api/Functions'
import globalContext from '../../context/GlobalContext'
Modal.setAppElement('#root');
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '31.77083vw',
    borderRadius: '1vw'
  },
};

const Index = () => {
  const [modalIsOpen, setIsOpen] = useState(false)
  const [expanPlan, setIsExpan] = useState(false)
  const [showPopup, setIsShow] = useState(false)
  const [showCheckoutPopup, setShowCheckoutPopup] = useState(false)

  const [plans, setPlans] = useState([])
  const [planList, setPlanList] = useState([])
  const [popupPlan, setPopupPlan] = useState({})

  const [addOnsOTT, setAddOnsOTT] = useState([])
  const [planAddonList, setPlanAddonList] = useState([])

  const plansRef = useRef(null)
  const checkout = useContext(globalContext);
  const [selectedPlan, setSelectedPlan] = useState({})


  useEffect(() => {

    fetchPlanList()

  }, []);

  const fetchPlanList = async () => {

    try {

   
      let param = await encryptData({ "Source": "NDLP" })
      const appData = await getPlanList(param);
      var filterData = filterPlans(appData.Result, 'Monthly');
      setPlanList(filterData);
      setPlans(appData)

    } catch (e) {
      console.error(e);
    }


  }

  const scrollTo = () => plansRef.current.scrollIntoView()
  const openModal = async (plan) => {
    setIsOpen(true);
    let data = await getActualSubscriptionPlanBasedOnCPAddOn({subscriptionPlanID : plan.SubscriptionPlanID, ottSMSID : 0});
    setAddOnsOTT(data);

    setPopupPlan(plan)

  }
  const afterOpenModal = () => {
    //
  }
  const closeModal = () => {
    setIsOpen(false);
  }
  const toggleExpand = () => {
    if (expanPlan) {
      setIsExpan(false)
    } else {
      setIsExpan(true)
    }
  }
  const showSubsPopup = (plan) => {
    setIsShow(true)
    
    checkout.setCheckoutPlan(plan)
    // setSelectedPlan(plan)

  }
 
  var selected_value = [];



  const selectPlanEvent = (status, plan) => {
    setSelectedPlan(plan);
    setShowCheckoutPopup(status)
  }


  return (
    <Fragment>
      <Header />
      <Banner />
      <div className='wrapper'>
        <div className='cardWrapper' ref={plansRef}>

          {
            planList.map((data) => {
              if (!(data.SubscriptionPlanName).toLowerCase().includes('watcho max')) {

                return (<Card plan={data} handleClick={openModal} showSubsPopup={showSubsPopup} plans={plans} planAddonList={planAddonList} setShowCheckoutPopup={setShowCheckoutPopup} selectPlanEvent={selectPlanEvent}/>)
              }
              // return (<Card plan={data} setPlanList={setPlanList} handleClick={openModal}/>);  

            })
          }

        </div>
        <section className='subsSteps'>
          <h2 className='sub-heading'>How to Buy Flexi Plans Subscription</h2>
          <div className='stepWrap'>
            <div className='setpBox'>
              <span>1</span>
              <span>2</span>
              <span>3</span>
              <span>4</span>
            </div>
            <div className='setpBoxTet'>
              <span>Add your favourite OTT's</span>
              <span>Select a plan</span>
              <span>Verify your number</span>
              <span>Make payment</span>
            </div>
          </div>
          <button className='subsplan-btn' onClick={scrollTo}>Subscribe A Plan</button>
        </section>
        {/* <section className='compareSec'>
          <h3 className='expand-txt'>
            Need Help!  
            <button className='expand-btn' onClick={toggleExpand}>Compare & Make A Choice <i className="fa fa-angle-down"></i></button>
          </h3>
          <div className='plansCmpr'>
            <Plans expanPlan={expanPlan} handleClick={openModal} showSubsPopup={showSubsPopup} compareList={compareList} setCompareList={setCompareList} plans={plans}/>
          </div>
        </section> */}
        <section className='faqsSec'>
          <h2 className='sub-heading'>FAQ's</h2>
          <Accordion questionsAnswers={questionsAnswers} />
        </section>
        <div className='scrollto'>
          <button onClick={scrollTo}>Back to plans</button>
        </div>
      </div>
      <Footer />
      {showCheckoutPopup ? 
      <Popups showCheckoutPopup={showCheckoutPopup} setShowCheckoutPopup={setShowCheckoutPopup} selectedPlan={selectedPlan} selectPlanEvent={selectPlanEvent} />
        : null}

      {/* <Popups showPopup={showPopup} closePopup={closePopup} /> */}
      {/* <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button onClick={closeModal} className='btn-close'><img src={require('../../assets/images/close.png')} alt='close' /></button>
        <div className='modal-content'>

          <h2 className='modal-heading'>Select Apps</h2>
          <p className='sub-desc'>{popupPlan.SubscriptionRule?.SubscriptionRuleMSG}</p>

          <div className='apps-block'>
            <p>Popular Apps</p>
            <ul className='ott-list'>
              {
                addOnsOTT.map((data) => {
                  if (data.PackType == 'ACP') {
                    return (
                      <li>
                        <div className='platformname'>{data.PackageName}</div>
                        <img src={data.SubscriptionApp[0].AppIconUrl} alt='' />
                        <div className='platformprice'><input type='checkbox' value={data.PackageID} onChange={(e) => { selectPlanAddons(e, popupPlan.SubscriptionPlanID, JSON.stringify(data.SubscriptionApp[0])) }}/></div>
                      </li>
                    )
                  }
                })
              }

            </ul>

            <p>Regional Apps</p>
            <ul className='ott-list'>
              {
                addOnsOTT.map((data) => {
                  if (data.PackType == 'RCP') {
                    return (
                      <li>
                        <div className='platformname'>{data.PackageName}</div>
                        <img src={data.SubscriptionApp[0].AppIconUrl} alt='' />
                        <div className='platformprice'><input type='checkbox' value={data.PackageID}  onChange={(e) => { selectPlanAddons(e, popupPlan.SubscriptionPlanID, JSON.stringify(data.SubscriptionApp[0])) }}/></div>
                      </li>
                    )
                  }
                })
              }

            </ul>
          </div>

          <div className='modal-footer'>
            <button className='subsplan-btn' onClick={() => closePopup()}>Done</button>
          </div>
        </div>
      </Modal> */}
    </Fragment>
  )
}

export default Index
