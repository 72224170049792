import React, { Fragment, useEffect, useState } from 'react'
import './card.css'
import { createSlug, filterOTTPlans, filterPlans, getTotalOfAllOtt, removeFromString } from '../../api/Functions'
import { Link } from 'react-router-dom'
import { pushGTMTAG } from '../../api/GTMTag'
import PlanModal from '../../containers/Home/PlanModal'
import { getActualSubscriptionPlanBasedOnCPAddOn } from '../../api/Activity'
import OttPlatformModal from '../../containers/Home/OttPlatformModal'

const Index = ({ plan, handleClick, showSubsPopup, plans, selectPlanEvent}) => {
  const [activePlan, setIsActiveplan] = useState('Annual')
  const [filterObj, setFilterObj] = useState(plan)
  const [modalIsOpen, setModalIsOpen] = useState(false)

  const [addOnsOTT, setAddOnsOTT] = useState([])
  const [planAddonList, setPlanAddonList] = useState([])
  const [popupPlan, setPopupPlan] = useState({})
  const [addOnError, setAddOnError] = useState(null)
  const [isOttPlatformModel, setIsOttPlatformModel] = useState(false)
  const [planAddons, setPlanAddons] = useState([])
  const [totalPlanAddons, setTotalsPlanAddons] = useState({ 'regional': 0, 'popular': 0 })

  const [totalOttPrice, setTotalOttPrice] = useState(getTotalOfAllOtt(plan.SubscriptionApps))

  const openModal = async (plan) => {
    setModalIsOpen(true);
    let data = await getActualSubscriptionPlanBasedOnCPAddOn({ subscriptionPlanID: plan.SubscriptionPlanID, ottSMSID: 0 });
    setAddOnsOTT(data);
    setPopupPlan(plan)

  }

  const openCloseModal = (status, plan = null) => {
    if (status) {
      setPopupPlan(plan)
    }
    setIsOttPlatformModel(status)
  }

  const handleChange = (event, SubscriptionPlanName) => {
    const target = event.target;
    const value = target.value;
    setIsActiveplan(value);

    let temp = filterPlans(plans.Result, value, SubscriptionPlanName);
    setFilterObj(temp[0])
    setPlanAddonList([])
    setPlanAddons([])
    setTotalsPlanAddons({ 'regional': 0, 'popular': 0 })
    setTotalOttPrice(getTotalOfAllOtt(temp[0].SubscriptionApps))

  }
  const addRequireClassName = (num) => {
    if (num > 10) {
      return 'grater-10';
    } else if (num == 10) {
      return 'equal-10';
    } else if (num > 8) {
      return 'equal-10';
    } else if (num > 6) {
      return 'equal-8';
    } else {
      return 'equal-6';
    }
  }


  const closeModal = () => {

    setModalIsOpen(false)

  }

  const oldclosePopup = async (planAddons, totalPlanAddons) => {
    var total_apps = totalPlanAddons['regional'] + totalPlanAddons['popular'];
    var is_error = false;
    if(total_apps == 0) {
      setAddOnError(`(Please select mininum ${filterObj.SubscriptionRule.TotalMinimumAllowed} apps)`)
      is_error = true;
    }
    else if (totalPlanAddons['regional'] + totalPlanAddons['popular'] == 0) {
      setAddOnError("(Please select " + (filterObj.SubscriptionRule.ApplicableAddOn + filterObj.SubscriptionRule.ApplicableRegional) + " OTT's)")
      is_error = true;

    }
    else {

      if (filterObj.SubscriptionRule.ApplicableRegional != 0 || filterObj.SubscriptionRule.ApplicableAddOn != 0) {
        if (totalPlanAddons['regional'] === 0) {
          setAddOnError(`(Please select ${filterObj.SubscriptionRule.ApplicableRegional} regional OTT's)`)
          is_error = true;

        }
        else if (totalPlanAddons['regional'] > filterObj.SubscriptionRule.ApplicableRegional) {
          // setAddOnError("(Please select "+filterObj.SubscriptionRule.ApplicableRegional+" regional OTT's)")
          setAddOnError(`(You can add only ${filterObj.SubscriptionRule.ApplicableRegional} Regional App of your choice)`)
          is_error = true;

        }
        else if (totalPlanAddons['regional'] < filterObj.SubscriptionRule.ApplicableRegional) {
          setAddOnError("(Sorry! You can only add " + filterObj.SubscriptionRule.ApplicableRegional + " regional OTT's)")
          is_error = true;
        }
        if (filterObj.SubscriptionRule.ApplicableAddOn != 0) {
          if (totalPlanAddons['popular'] === 0) {
            setAddOnError(`(Please select ${filterObj.SubscriptionRule.ApplicableRegional} popular OTT's)`)
            is_error = true;

          }
          else if (totalPlanAddons['popular'] > filterObj.SubscriptionRule.ApplicableAddOn) {
            setAddOnError(`(You can add only ${filterObj.SubscriptionRule.ApplicableRegional} Popular App of your choice)`)

            // setAddOnError("(Please select "+filterObj.SubscriptionRule.ApplicableAddOn+" popular OTT's)")
            is_error = true;
          }
          else if (totalPlanAddons['popular'] < filterObj.SubscriptionRule.ApplicableAddOn) {
            setAddOnError("(Sorry! You can only add " + filterObj.SubscriptionRule.ApplicableAddOn + " popular OTT's)")
            is_error = true;
          }
        }
      }
      if (!is_error) {

        setAddOnError(null)
        var data = await filterOTTPlans(addOnsOTT, planAddons);

        setPlanAddonList(data);

        var filter = { ...filterObj };
        filter['addonplans'] = data;
        // filter['total_ott_price'] = getTotalOfAllOtt(filterObj.SubscriptionApps, data);

        setFilterObj(filter)
        setModalIsOpen(false)
        setTotalOttPrice(getTotalOfAllOtt(filterObj.SubscriptionApps, data))
      }


    }

    const getData = setTimeout(() => {
      setAddOnError(null)

    }, 3000);
  }

  const closePopup = async (planAddons, totalPlanAddons) => {
    var total_apps = totalPlanAddons['regional'] + totalPlanAddons['popular'];
    var is_error = false;
    if(total_apps < filterObj.SubscriptionRule.TotalMinimumAllowed) {
      setAddOnError(`(Please select mininum ${filterObj.SubscriptionRule.TotalMinimumAllowed} apps)`)
      is_error = true;
    }
    else if (filterObj.SubscriptionRule.ApplicableRegional != 0 && totalPlanAddons['regional'] > filterObj.SubscriptionRule.MaxRegional) {
      setAddOnError(`(You can add only ${filterObj.SubscriptionRule.MaxRegional} Regional App of your choice)`)
      is_error = true;

    }

    else if (filterObj.SubscriptionRule.ApplicableAddOn != 0 && totalPlanAddons['popular'] > filterObj.SubscriptionRule.MaxAddOn) {
      setAddOnError(`(You can add only ${filterObj.SubscriptionRule.MaxAddOn} Popular App of your choice)`)
      is_error = true;

    }
   
    else {

      // if (filterObj.SubscriptionRule.ApplicableRegional != 0 || filterObj.SubscriptionRule.ApplicableAddOn != 0) {
      //   if (totalPlanAddons['regional'] === 0) {
      //     setAddOnError(`(Please select ${filterObj.SubscriptionRule.ApplicableRegional} regional OTT's)`)
      //     is_error = true;

      //   }
      //   else if (totalPlanAddons['regional'] > filterObj.SubscriptionRule.ApplicableRegional) {
      //     // setAddOnError("(Please select "+filterObj.SubscriptionRule.ApplicableRegional+" regional OTT's)")
      //     setAddOnError(`(You can add only ${filterObj.SubscriptionRule.ApplicableRegional} Regional App of your choice)`)
      //     is_error = true;

      //   }
      //   else if (totalPlanAddons['regional'] < filterObj.SubscriptionRule.ApplicableRegional) {
      //     setAddOnError("(Sorry! You can only add " + filterObj.SubscriptionRule.ApplicableRegional + " regional OTT's)")
      //     is_error = true;
      //   }
      //   if (filterObj.SubscriptionRule.ApplicableAddOn != 0) {
      //     if (totalPlanAddons['popular'] === 0) {
      //       setAddOnError(`(Please select ${filterObj.SubscriptionRule.ApplicableRegional} popular OTT's)`)
      //       is_error = true;

      //     }
      //     else if (totalPlanAddons['popular'] > filterObj.SubscriptionRule.ApplicableAddOn) {
      //       setAddOnError(`(You can add only ${filterObj.SubscriptionRule.ApplicableRegional} Popular App of your choice)`)

      //       // setAddOnError("(Please select "+filterObj.SubscriptionRule.ApplicableAddOn+" popular OTT's)")
      //       is_error = true;
      //     }
      //     else if (totalPlanAddons['popular'] < filterObj.SubscriptionRule.ApplicableAddOn) {
      //       setAddOnError("(Sorry! You can only add " + filterObj.SubscriptionRule.ApplicableAddOn + " popular OTT's)")
      //       is_error = true;
      //     }
      //   }
      // }
      if (!is_error) {

        setAddOnError(null)
        var data = await filterOTTPlans(addOnsOTT, planAddons);

        setPlanAddonList(data);

        var filter = { ...filterObj };
        filter['addonplans'] = data;
        // filter['total_ott_price'] = getTotalOfAllOtt(filterObj.SubscriptionApps, data);

        setFilterObj(filter)
        setModalIsOpen(false)
        setTotalOttPrice(getTotalOfAllOtt(filterObj.SubscriptionApps, data))
      }


    }

    const getData = setTimeout(() => {
      setAddOnError(null)

    }, 3000);
  }
  return (
    <>
    <div className={`subsCard ${(filterObj.SubscriptionPlanName).toLowerCase().includes('max') ?  'best-buy-subsCard' : ""} ${removeFromString(['Flexi'], filterObj.SubscriptionPlanName).toLowerCase()}`} key={filterObj.SubscriptionPlanName}>
      {
        (filterObj.SubscriptionPlanName).toLowerCase().includes('max') ?
        <div className='bestBuy'>
        <div className="bestBuy-wrap">
          <img src={require("../../assets/images/best-buy.png")} alt="" />
          <span>Best Buy</span>
        </div>
    </div>
        : ''
      }

      <div className='cardHeader'>
        <h3 className='packageName'>
        Flexi-<span>{removeFromString(['Flexi-', 'Flexi '], filterObj.SubscriptionPlanName)}</span>
        </h3>
        <div className='packagePrice'>
          <div className='priceBox'>
            <div className='discounted'>&#8377; {filterObj.SubscriptionPriceWithTax}</div>
            {/* <div className='actual'>(&#8377; {(filterObj.SubscriptionPlanDurationName).toLowerCase() == 'annual' ? Math.ceil(filterObj.SubscriptionDisplayPriceWithTax/12)+"/M" : Math.ceil(filterObj.SubscriptionDisplayPriceWithTax/30)+"/D"})</div> */}
          </div>
         
        </div>
      </div>
      <div className='cardBody'>
          {
            filterObj.SubscriptionApps ?
              <>
                <p>{(filterObj.SubscriptionApps.length)} Base Apps +</p>
                <h4>Any {(filterObj.SubscriptionRule.ApplicableAddOn) + (filterObj.SubscriptionRule.ApplicableRegional)} Apps</h4>

                <ul className={`platforms-list plan-ott-plateform ${filterObj.SubscriptionApps && addRequireClassName(((filterObj.SubscriptionApps).length + (filterObj.addonplans ? filterObj.addonplans.length : 0)))}`}>
                  {
                    filterObj.SubscriptionApps.map((row) => {
                      return (<li>
                        <img src={row.AppIconUrl} alt={row.AppName} /></li>
                      )
                    })
                  }
                  <li ><span className='' >+</span></li>
                  {
                    (filterObj?.addonplans)?.map((row) => {

                      return (<li>
                       
                        <img src={row.SubscriptionApp[0]?.AppIconUrl} alt={row.SubscriptionApp[0]?.AppName} /></li>
                      )
                    })
                  }
                  {
                    (filterObj.SubscriptionRule.TotalMinimumAllowed > (filterObj.addonplans ? filterObj.addonplans.length : 0)) ?

                      <li><div className='addmore add-more-btn' onClick={() => openModal(plan)} >Add {((filterObj.SubscriptionRule.TotalAddonAllowed) )} more</div></li>
                      : ''
                  }
                </ul>
                {
                  (filterObj.SubscriptionRule.TotalMinimumAllowed > (filterObj.addonplans ? filterObj.addonplans.length : 0)) ? '' : (<div className="changeOtts" onClick={() => openModal(plan)} ><img src={require('../../assets/images/icons/reload.png')} alt="reload" /><span>Change the OTT's</span></div>)
                }

              </>
              : ""
          }
      
        </div>
        <div className='bottomWrap'>
        <div className='saveUp'>You save upto  {filterObj.Discount}%</div>
          {
            filterObj.SubscriptionRule.TotalMinimumAllowed > (filterObj.addonplans ? filterObj.addonplans.length : 0) ?

              <Link type='button' onClick={() => pushGTMTAG(filterObj, 'buy_now')} className="btn-subs inactive-subscription" >Subscribe Now</Link>
              :
              <button type='button' onClick={() => {pushGTMTAG(filterObj, 'buy_now');selectPlanEvent(true, filterObj);}} className="btn-subs active-subscription" >Subscribe Now</button>

              // <Link type='button' onClick={() => pushGTMTAG(filterObj, 'buy_now')} to={`/planoffer/${createSlug((removeFromString(['WATCHO'], filterObj.SubscriptionPlanName)))}`} state={filterObj} className="btn-subs active-subscription" >Subscribe Now</Link>

          }

          <button onClick={() => openCloseModal(true, filterObj)} className='btn-readmore'>Know more</button>
       </div>
        <div >

          <OttPlatformModal isOttPlatformModel={isOttPlatformModel} setIsOttPlatformModel={setIsOttPlatformModel} openCloseModal={openCloseModal} popupPlan={popupPlan} planAddonList={planAddonList} ottPrice={totalOttPrice} />
        </div>
      </div>
      <PlanModal modalIsOpen={modalIsOpen} popupPlan={popupPlan}
        addOnsOTT={addOnsOTT} closeModal={closeModal} planAddonList={planAddonList} closePopup={closePopup} addOnError={addOnError} planAddons={planAddons} setPlanAddons={setPlanAddons} totalPlanAddons={totalPlanAddons} setTotalsPlanAddons={setTotalsPlanAddons} setAddOnError={setAddOnError} />

    </>
  )
}

export default Index
